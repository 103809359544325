export class NotaC {
    constructor(
        public _id: String,
        public estudiante:String,
        public materia:String,
        public periodo:String,
        public Putarea1:String,
        public Putarea2:String,
        public Putarea3:String,
        public Putarea4:String,
        public Puexamen:String,


        public Sutarea1:String,
        public Sutarea2:String,
        public Sutarea3:String,
        public Sutarea4:String,
        public sesenta_p1:String,
        public Suexamen:String,
        public Veinte_P1:String,
        public Prointegrador1:String,
        public Veinte_Pi1:String,
        public Promedio_P1:String,
        public Ponderacion_P1:String,

        public Tutarea1:String,
        public Tutarea2:String,
        public Tutarea3:String,
        public Tutarea4:String,
        public Tuexamen:String,

        public Cutarea1:String,
        public Cutarea2:String,
        public Cutarea3:String,
        public Cutarea4:String,
        public Cuexamen:String,

        public Qutarea1:String,
        public Qutarea2:String,
        public Qutarea3:String,
        public Qutarea4:String,
        public Ochenta_P:String,
        public examenFinal:String,
        public Veinte_P2:String,
        public Prointegrador2:String,
        public Veinte_Pi2:String,
        public promedio_P2:String,
        public Ponderacion_P2:String,
        public Prointegradorf:String,
        public PonderacionPIF:String,
        
        public examenSupletorio:String,

        public pt:String,
    ) { }
}

