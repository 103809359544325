export class NotaBasica {
    constructor(
        public _id: String,
        public estudiante:String,
        public materia:String,
        public periodo:String,
        public Q1P1insumo1:String,
        public Q1P1insumo2:String,
        public Q1P1insumo3:String,
        public Q1P1insumo4:String,
        public Q1P1insumo5:String,
        public Q1P1insumo6:String,
        public Q1P2insumo1:String,
        public Q1P2insumo2:String,
        public Q1P2insumo3:String,
        public Q1P2insumo4:String,
        public Q1P2insumo5:String,
        public Q1P2insumo6:String,
        public Q1P3insumo1:String,
        public Q1P3insumo2:String,
        public Q1P3insumo3:String,
        public Q1P3insumo4:String,
        public Q1P3insumo5:String,
        public Q1P3insumo6:String,
        public Ochenta_P1:String,
        public examen1:String,
        public Veinte_P1:String,
        public Promedio1:String,
        public Q2P1insumo1:String,
        public Q2P1insumo2:String,
        public Q2P1insumo3:String,
        public Q2P1insumo4:String,
        public Q2P1insumo5:String,
        public Q2P1insumo6:String,
        public Q2P2insumo1:String,
        public Q2P2insumo2:String,
        public Q2P2insumo3:String,
        public Q2P2insumo4:String,
        public Q2P2insumo5:String,
        public Q2P2insumo6:String,
        public Q2P3insumo1:String,
        public Q2P3insumo2:String,
        public Q2P3insumo3:String,
        public Q2P3insumo4:String,
        public Q2P3insumo5:String,
        public Q2P3insumo6:String,
        public Ochenta_P2:String,
        public examen2:String,
        public Veinte_P2:String,
        public Promedio2:String,
        public examenSupletorio:String,
        public examenRemedial:String,
        public examenGracia:String,
        public pt:String,
    ) { }
}

