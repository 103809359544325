export class Insumo {
    constructor(
        public _id: String,
        public materia:String,
        public periodo:String,
        public Descinsumo1:String,
        public Descinsumo2:String,
        public Descinsumo3:String,
        public Descinsumo4:String,
        public Descinsumo5:String,
        public Descinsumo6:String,
        public Descinsumo7:String,
        public Descinsumo8:String,
        public Descinsumo11:String,
        public Descinsumo22:String,
        public Descinsumo33:String,
        public Descinsumo44:String,
        public Descinsumo55:String,
        public Descinsumo66:String,
        public Descinsumo77:String,
        public Descinsumo88:String,
    
    ) { }
}



