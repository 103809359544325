
export class Materia {
    constructor(
        public _id: String,
        public nombre: String,
        public codigo:String,
        public codigoD: String,
        public codigoC: String,
        public periodo:String,
        public estado: String
    ) { }
}




