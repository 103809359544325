export class Curso{
    
    constructor(
        public _id: String,
        public curso: String,
        public paralelo: String,
        public estado: String,
        public periodo:String
    ) { 


    }
}