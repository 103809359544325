export class InsumoC {
    constructor(
        public _id: String,
        public estudiante:String,
        public materia:String,
        public periodo:String,
        public Descput1:String,
        public Descput2:String,
        public Descput3:String,
        public Descput4:String,

        public Descsut1:String,
        public Descsut2:String,
        public Descsut3:String,
        public Descsut4:String,

        public Desctut1:String,
        public Desctut2:String,
        public Desctut3:String,
        public Desctut4:String,


        public Desccut1:String,
        public Desccut2:String,
        public Desccut3:String,
        public Desccut4:String,


        public Descqut1:String,
        public Descqut2:String,
        public Descqut3:String,
        public Descqut4:String,
        
    ) { }
}

