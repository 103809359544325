export class InsumoBasica {
    constructor(
        public _id: String,
        public materia:String,
        public periodo:String,
        public DescQ1P1insumo1:String,
        public DescQ1P1insumo2:String,
        public DescQ1P1insumo3:String,
        public DescQ1P1insumo4:String,
        public DescQ1P1insumo5:String,
        public DescQ1P1insumo6:String,
        public DescQ1P2insumo1:String,
        public DescQ1P2insumo2:String,
        public DescQ1P2insumo3:String,
        public DescQ1P2insumo4:String,
        public DescQ1P2insumo5:String,
        public DescQ1P2insumo6:String,
        public DescQ1P3insumo1:String,
        public DescQ1P3insumo2:String,
        public DescQ1P3insumo3:String,
        public DescQ1P3insumo4:String,
        public DescQ1P3insumo5:String,
        public DescQ1P3insumo6:String,
   
        public DescQ2P1insumo1:String,
        public DescQ2P1insumo2:String,
        public DescQ2P1insumo3:String,
        public DescQ2P1insumo4:String,
        public DescQ2P1insumo5:String,
        public DescQ2P1insumo6:String,
        public DescQ2P2insumo1:String,
        public DescQ2P2insumo2:String,
        public DescQ2P2insumo3:String,
        public DescQ2P2insumo4:String,
        public DescQ2P2insumo5:String,
        public DescQ2P2insumo6:String,
        public DescQ2P3insumo1:String,
        public DescQ2P3insumo2:String,
        public DescQ2P3insumo3:String,
        public DescQ2P3insumo4:String,
        public DescQ2P3insumo5:String,
        public DescQ2P3insumo6:String,

    ) { }
}

