export class CalculableC {
    constructor(

        public sesentaporciento:String,
        public veinteporcientoP1:String,
        public veinteporcientoPI1:String,
        public promedioP1:String,
        public ponderadoPI1:String,
        public ochentaporciento:String,
        public veinteporciento:String,
        public veinteporcientoPI2:String,
        public promedioP2:String,
        public ponderadoPI2:String,
        public ponderadoPIF:String,
        public promedioFinal:String,
        public estadonotabgu:String

    ) { }
}

